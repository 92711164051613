import ToastDialog from './components/Toast'
import { AppError } from '../../classes/app-error'

export default function(Vue, options) {
  const ComponentClass = Vue.extend(ToastDialog)
  const toast = new ComponentClass()

  const app = document.body
  const container = document.createElement('div')
  app.appendChild(container)
  app.appendChild(toast.$mount().$el)

  return {
    error: (text, options = {}) => {
      let message = 'Произошла ошибка'

      if (text instanceof AppError) {
        message = text.userMessage
      } else if (text instanceof Error) {
        console.error(text)
      } else if (typeof text === 'string') {
        message = text
      } else {
        message = (text || 'Неизвестная ошибка').toString()
      }
      options = { text: message, color: 'error', ...options }
      return toast.show(options)
    },
    success: (text, options) => toast.show(Object.assign({ text: text, color: 'success' }, options)),
    info: (text, options) => toast.show(Object.assign({ text: text, color: 'info' }, options)),
    warning: (text, options) => toast.show(Object.assign({ text: text, color: 'warning' }, options)),
    show: toast.show
  }
}
