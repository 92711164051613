import { VideoroomApiFactory } from '@common/vue-plugins/janus/vue-plugin'
import Vue from 'vue'
import CommutatorModule from '@cpanel/store/modules/commutator'
import $store from '../index'

const $janus = VideoroomApiFactory.getInstance()

const state = {
  //
  conferenceId: null,
  // Id видеокомнаты на серевре Janus
  roomId: null,
  // Пинкод для доступа к видеокомнате
  pin: null,
  // Данные об абоненте, с которым разговариваем
  caller: null,
  //
  playing: false,
  //
  remoteStream: null,
  //
  localStream: null
}

// getters
const getters = {
  roomId(state) {
    return state.roomId
  },
  pin(state) {
    return state.pin
  },
  caller() {
    return state.caller
  },
  localStream(state) {
    return state.localStream
  },
  remoteStream(state) {
    return state.remoteStream
  },
  playing(state) {
    return state.playing
  }
}

// actions
const actions = {
  async joinRoom({ state, commit }) {
    // Подключаемся к предоставленной видеокомнате
    await $janus.joinRoom({ roomId: state.roomId, pin: state.pin })
    $janus.on($janus.EVENTS.DISCONNECTED, () => {
      commit('SET_CONFERENCE_ID', null)
      commit('SET_ROOM_ID', null)
      commit('SET_PIN', null)
      commit('SET_CALLER', null)
    })
    $janus.on($janus.EVENTS.ROOM_PUBLISHER_IN, ({ detail }) => {
      const publisher = detail[0]
      $janus.subscribeTo(publisher.id, { audio: true, video: true })
        .then(subscriber => {
          commit('SET_REMOTE_STREAM', subscriber._streams[0])
        })
    })
    $janus.on($janus.EVENTS.ROOM_PUBLISHER_OUT, ({ detail: publisherId }) => {
      commit('SET_REMOTE_STREAM', null)
      $janus.unsubscribeFrom(publisherId)
    })
  },

  async disconnect({ commit }) {
    try {
      // Отключаемся от видеокомнаты
      await $janus.disconnect()
    } catch (e) {
      console.warn(e)
    }
    commit('SET_CONFERENCE_ID', null)
    commit('SET_ROOM_ID', null)
    commit('SET_PIN', null)
  },

  async play({ state, commit }) {
    if (!state.roomId || !$janus.isRoomJoined()) {
      console.warn(`[VIDEOCHAT STORE] Cannot play. Does not joined to room yet.`)
      return false
    }
    if (!$janus.isPublished) {
      await $janus.publishMe({ audio: true, video: true })
      commit('SET_PLAYING', true)
      commit('SET_LOCAL_STREAM', $janus._stream)
    }
  },

  async unplay({ state, commit }, data) {
    if (!state.roomId || !$janus.isRoomJoined()) return
    if ($janus.isPublished) {
      await $janus.unpublishMe(true)
      commit('SET_PLAYING', false)
      commit('SET_LOCAL_STREAM', null)
    }
  },

  async start({ state, commit, dispatch }, data) {
    const { conferenceId, room, visitor } = data
    commit('SET_CONFERENCE_ID', conferenceId)
    commit('SET_ROOM_ID', room.id)
    commit('SET_PIN', room.pin)
    commit('SET_CALLER', visitor)
    await dispatch('joinRoom')
    await dispatch('play')
  },

  async stop({ commit, dispatch }, data) {
    await dispatch('unplay')
    await $janus.unsubscribeAll()
    await dispatch('disconnect')
    commit('SET_CALLER', null)
  },

  async hangup({ state }) {
    if (state.conferenceId) {
      await this.dispatch('commutator/hangup', state.conferenceId)
    }
    // Это просто уведомление о желании завершить конференцию.
    // На сервер будет отправлена команда об окончании конференции.
    // Сервер разошлет всем участникам команду [stop conference], в том числе и нам.
    // Мы получим эту команду, сработает событие onCallEnd, которое вызовет dispatch('videochat/stop')
    // И там мы уже реально освободим все ресурсы
  }
}

// mutations
const mutations = {
  SET_ROOM_ID(state, roomId) {
    state.roomId = roomId
  },

  SET_PIN(state, pin) {
    state.pin = pin
  },

  SET_CONFERENCE_ID(state, data) {
    state.conferenceId = data
  },

  SET_CALLER(state, data) {
    state.caller = data
  },

  SET_PLAYING(state, val) {
    state.playing = val
  },

  SET_LOCAL_STREAM(state, stream) {
    if (!stream) $janus.stopLocalStreams()
    state.localStream = stream
  },

  SET_REMOTE_STREAM(state, stream) {
    state.remoteStream = stream
  }
}

const events = {
  onCall(data) {
    const { conferenceId, room, visitor } = data
    this.dispatch('videochat/start', { conferenceId, room, visitor })
  },
  onCallEnd() {
    this.dispatch('videochat/stop')
  },
  onCommutatorConnected() {
    // nop
  },
  onCommutatorDisconnected() {
    // return this.dispatch('videochat/stop')
  }
}

const vm = new Vue()
vm.$watch(
  () => (CommutatorModule.state.call),
  data => {
    data ? events.onCall.call($store, data) : events.onCallEnd.call($store)
  }
)
vm.$watch(() => CommutatorModule.state.sessionId, val => {
  val ? events.onCommutatorConnected.call($store) : events.onCommutatorDisconnected.call($store)
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
