import Janus from 'janus-gateway-js'

function VideoroomPlugin() {
  Janus.MediaPlugin.apply(this, arguments)
}

VideoroomPlugin.NAME = 'janus.plugin.videoroom'
VideoroomPlugin.prototype = Object.create(Janus.MediaPlugin.prototype)
VideoroomPlugin.prototype.constructor = VideoroomPlugin

/**
 * Подключение к комнате
 * @param options
 * @return {Promise<*>}
 */
VideoroomPlugin.prototype.join = async function(options = {}) {
  const body = Object.assign({
    request: 'join',
    ptype: 'publisher'
  }, options)
  const response = await this.sendWithTransaction({ body: body })
  const { _plainMessage: { jsep, plugindata: { data }}} = response
  return { ...data, jsep }
}

VideoroomPlugin.prototype.leave = async function(options = {}) {
  const body = Object.assign({ request: 'leave' }, options)
  const response = await this.sendWithTransaction({ body: body })
  return response
}

VideoroomPlugin.prototype.publish = async function(stream, options = {}) {
  const body = Object.assign({
    request: 'publish',
    audio: true,
    video: true,
    data: false
  }, options)

  this.createPeerConnection()
  stream.getTracks().forEach(track => this.addTrack(track, stream))

  const offer = await this.createOffer()
  const response = await this.sendWithTransaction({ body, jsep: offer })

  const jsep = response.get('jsep')
  if (jsep) await this.setRemoteSDP(jsep)
  return response
}

VideoroomPlugin.prototype.listParticipants = async function(roomId, options = {}) {
  const body = Object.assign({ request: 'listparticipants', room: parseInt(roomId) }, options)
  const response = await this.sendWithTransaction({ body: body })
  const { _plainMessage: { plugindata: { data: { participants }}}} = response
  return participants
}

VideoroomPlugin.prototype.unpublish = async function(options = {}) {
  const body = { request: 'unpublish', ...options }
  const response = await this.sendWithTransaction({ body })
  this.closePeerConnection()

  return response
}

VideoroomPlugin.prototype.joinAsSubscriber = async function(options = {}) {
  const body = Object.assign({
    request: 'join',
    ptype: 'subscriber'
  }, options)
  const response = await this.sendWithTransaction({ body: body })
  const { _plainMessage: { jsep, plugindata: { data }}} = response

  this.createPeerConnection()
  const answer = await this.createAnswer(jsep)

  await this.sendWithTransaction({ body: { request: 'start' }, jsep: answer })

  return data
}

VideoroomPlugin.prototype.start = async function(options = {}) {
  const body = { request: 'start', ...options }
  const response = await this.sendWithTransaction({ body })
  return response
}

export default VideoroomPlugin
