import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import dialog from './modules/dialog'
import user from './modules/user'
import preview from './modules/preview'
import commutator from './modules/commutator'
import videochat from './modules/videochat'
import chat from './modules/chat'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    dialog,
    user,
    preview,
    commutator,
    videochat,
    chat
  }
})

export default store
