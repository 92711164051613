import Vue from 'vue'
import { apolloProvider } from '@cpanel/includes/apollo/apollo-default-provider'
import { AppError } from '@common/classes/app-error'
import { commutator } from '@cpanel/includes/commutator'
import gqlCreateSessionMutation from '@cpanel/graphql/commutator-create-session.mutation.gql'

const $apollo = apolloProvider.defaultClient

const state = Vue.observable({
  // Id сессии на коммутаторе
  sessionId: null,
  // Данные о входящем вызове. Вызов еще не принят, его можно отклонить
  incomingCall: null,
  // Данные о видеозвонке. Звонок уже принят, идет общение
  call: null
})

// getters
const getters = {

  sessionId(state) {
    return state.sessionId
  },

  connected(state) {
    return !!state.sessionId
  },

  instance() {
    return commutator
  },

  incomingCall(state) {
    return state.incomingCall
  },

  call(state) {
    return state.call
  }
}

// actions
const actions = {
  async register({ state, commit, rootGetters }) {
    const placeId = rootGetters['user/placeId']
    if (!placeId) throw new AppError('Не выбрана точка продаж для вещания')

    // Запрашиваем бэкенд создать для нас сессию на коммутаторе
    const { data: { createSession: { sessionId }}} = await $apollo.mutate({
      mutation: gqlCreateSessionMutation,
      variables: { reg: { placeId }}
    })

    // Теперь подключаемся к коммутатору ...
    await commutator.connect(sessionId, {})
    commit('SET_SESSION_ID', sessionId)

    commutator.addEventListener(
      commutator.EVENTS.INCOMING_CALL,
      ({ detail: data }) => { commit('SET_INCOMING_CALL', data) }
    )
    commutator.addEventListener(
      commutator.EVENTS.INCOMING_CALL_CANCEL,
      ({ detail: data }) => { commit('SET_INCOMING_CALL_CANCEL', data) }
    )
    commutator.addEventListener(
      commutator.EVENTS.START_CONFERENCE,
      ({ detail: data }) => {
        commit('SET_CALL', data)
        const { requestId, ...etc } = data
        commutator.startConferenceConfirm(requestId, etc)
      }
    )
    commutator.addEventListener(
      commutator.EVENTS.STOP_CONFERENCE,
      ({ detail: data }) => {
        if (data && state.call && data.conferenceId === state.call.conferenceId) commit('SET_CALL', null)
      }
    )
    commutator.addEventListener(
      commutator.EVENTS.DISCONNECT,
      () => {
        commit('SET_SESSION_ID', null)
        commit('SET_INCOMING_CALL', null)
        commit('SET_CALL', null)
      }
    )
  },

  async unregister() {
    try {
      // Отключаемся от коммутатора
      commutator.disconnect()
    } catch (e) {
      console.warn(e)
    }
  },

  requestRoom() {
    return commutator.requestRoom()
  },

  async acceptCall({ state, commit }, data = {}) {
    if (!state.incomingCall) return
    commutator.acceptCall(state.incomingCall.requestId, data)
    commit('SET_INCOMING_CALL', null)
  },

  declineCall({ state, commit }) {
    if (!state.incomingCall) return
    commutator.declineCall(state.incomingCall.requestId)
    commit('SET_INCOMING_CALL', null)
  },

  hangup({ state }, conferenceId) {
    return commutator.hangup({ conferenceId })
  },

  async chatSendMessage({ state }, messageData) {
    const { chatId, message } = messageData || {}
    const result = await commutator.chatSendMessage(chatId, message)
    return result
  },

  async chatQueryMessages({ state }, data) {
    const { chatId, query } = data || {}
    const result = await commutator.chatQueryMessages(chatId, query)
    return result
  }
}

// mutations
const mutations = {
  SET_SESSION_ID(state, sessionId) {
    state.sessionId = sessionId
  },
  SET_INCOMING_CALL(state, data) {
    state.incomingCall = data
  },
  SET_INCOMING_CALL_CANCEL(state, data) {
    if (state.incomingCall && data && state.incomingCall.requestId === data.requestId) {
      state.incomingCall = null
    }
  },
  SET_CALL(state, data) {
    state.call = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
