import Prompt from './prompt'
import Confirm from './confirm'
import Alert from './alert'
import Toast from './toast'

function install(Vue, options = {}) {
  if (install.installed) return
  install.installed = true

  Vue.$dialog = Vue.prototype.$dialog = {
    alert: Alert(Vue, options).show,
    confirm: Confirm(Vue, options).show,
    prompt: Prompt(Vue, options).show
  }

  Vue.$toast = Vue.prototype.$toast = Toast(Vue, options)
}

const DialogPlugin = {
  install
}

export default DialogPlugin
