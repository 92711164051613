import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from '@cpanel/router'
import vuetify from '@cpanel/includes/vuetify'
import '@cpanel/includes/vue-leaflet'
import '@cpanel/plugins'
import { apolloProvider } from '@cpanel/includes/apollo/apollo-default-provider'
import '@cpanel/assets/css/app.css'
import '@common/polyfills/webrtc-peerconnection-polyfills'
import '@cpanel/filters'

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  apolloProvider,
  async created() {
    await this.$store.dispatch('user/restore')
  },
  render: h => h(App)
}).$mount('#app')
