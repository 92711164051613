<template>
  <v-snackbar
    v-model="dialog"
    absolute
    :color="color"
    :timeout="timeout"
    :multi-line="multiLine"
    :left="left"
    :right="right"
    :top="top"
    :bottom="bottom"
    class="app-toast"
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        text
        icon
        @click="hide"
      >
        ✖
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
const defaults = {
  text: '',
  color: 'info',
  timeout: 6000,
  multiLine: false,
  left: false,
  right: true,
  top: false,
  bottom: true
}

export default {
  data() {
    return {
      text: defaults.text,
      color: defaults.color,
      timeout: defaults.timeout,
      multiLine: defaults.multiLine,
      left: defaults.left,
      right: defaults.right,
      top: defaults.top,
      bottom: defaults.bottom,
      dialog: false,
      promise: null
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue === false && this.promise) {
        this.promise.resolve()
        this.promise = null
      }
    }
  },
  methods: {
    show(options = {}) {
      const self = this
      if (typeof options === 'string') {
        this.text = options
      } else {
        const self = this
        Object.keys(defaults).forEach(key => {
          self[key] = options.hasOwnProperty(key) ? options[key] : defaults[key]
        })
      }

      this.dialog = true
      return new Promise((resolve, reject) => { self.promise = { resolve, reject } })
    },

    hide() {
      this.dialog = false
    }

  }
}
</script>

<style scoped>
  .app-toast >>> .error {
    background-color: #C62828;
  }
  .app-toast >>> .success {
    background-color: #43A047;
  }
  .app-toast >>> .warning {
    background-color: #FFEB3B;
    color: #000000;
  }
  .app-toast >>> .info {
    background-color: #01579B;
  }
</style>
