import Janus from 'janus-gateway-js'

function Echotest() {
  Janus.MediaPlugin.apply(this, arguments)
  this.stream = null
}

Echotest.NAME = 'janus.plugin.echotest'
Echotest.prototype = Object.create(Janus.MediaPlugin.prototype)
Echotest.prototype.constructor = Echotest

/**
 * @param {boolean} state
 * @returns {Promise}
 * @fulfilled {RTCSessionDescription} jsep
 */
Echotest.prototype.start = async function(options) {
  const opts = Object.assign({
    video: true,
    audio: true,
    record: false,
    recordFile: 'magiclink-test'
  }, options)
  const stream = await this.getUserMedia({ audio: opts.audio, video: opts.video })
  this.createPeerConnection()
  stream.getTracks().forEach(track => this.addTrack(track, stream))

  const offer = await this.createOffer()
  const message = {
    body: Object.assign(
      {
        audio: opts.audio,
        video: opts.video,
        record: opts.record,
        filename: `/opt/janus/share/janus/recordings/${opts.recordFile}`
      },
      opts.audiocodec ? { audiocodec: opts.audiocodec } : {},
      opts.videocodec ? { videocodec: opts.videocodec } : {}
    ),
    jsep: offer
  }
  const response = await this.sendWithTransaction(message)
  const jsep = response.get('jsep')
  if (jsep) await this.setRemoteSDP(jsep)
  this.stream = stream
  return jsep
}

Echotest.prototype.mute = async function(isMuted) {
  if (this.stream) this.stream.getAudioTracks()[0].enabled = !isMuted
}

Echotest.prototype.hangup = async function() {
  await this.detach()
  await this.cleanup()
}

export default Echotest
