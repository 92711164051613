import JanusLib from 'janus-gateway-js'
import EchotestPlugin from './janus-plugins/echotest'
import VideoroomPlugin from './janus-plugins/videoroom'
import { VideoroomApi } from './api/videoroom'

// Подключаем плагины Януса
JanusLib.Plugin.register(EchotestPlugin.NAME, EchotestPlugin)
JanusLib.Plugin.register(VideoroomPlugin.NAME, VideoroomPlugin)

// Создаем янус-клиента по умолчанию
export const janusClient = new JanusLib.Client(
  process.env.VUE_APP_JANUS_SERVER_WS || 'ws://localhost:8188/webrtc',
  {
    token: process.env.VUE_APP_JANUS_TOKEN || 'token',
    apisecret: process.env.VUE_APP_JANUS_API_SECRET || 'apisecret',
    keepalive: process.env.VUE_APP_JANUS_KEEP_ALIVE || 'true'
  }
)

export class VideoroomApiFactory {
  static getInstance(options = {}) {
    return new VideoroomApi(janusClient, options)
  }
}

// default videoroomApi instance
export const videoroomApi = VideoroomApiFactory.getInstance()

export const VueJanusPlugin = {
  install: function(Vue, options = {}) {
    if (this.install.installed) return
    this.install.installed = true
    Vue.$janus = Vue.prototype.$janus = janusClient
  }
}
