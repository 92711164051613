import Vue from 'vue'
import Router from 'vue-router'

const MinimalLayout = () => import(/* webpackChunkName: "common" */ '@cpanel/layouts/MinimalLayout')
const AuthLayout = () => import(/* webpackChunkName: "common" */ '@cpanel/layouts/AuthLayout')
const LoginPage = () => import(/* webpackChunkName: "common" */ '@cpanel/pages/auth/LoginPage.vue')
const RegisterPage = () => import(/* webpackChunkName: "common" */ '@cpanel/pages/auth/RegisterPage')
const ForgotPasswordPage = () => import(/* webpackChunkName: "common" */ '@cpanel/pages/auth/ForgotPasswordPage')
const NotFoundPage = () => import(/* webpackChunkName: "common" */ '@cpanel/pages/404.vue')
const Videochat = () => import(/* webpackChunkName: "common" */ '@cpanel/pages/videochat/Videochat')

const ConsoleLayout = () => import(/* webpackChunkName: "main" */ '@cpanel/layouts/ConsoleLayout')
const HomePage = () => import(/* webpackChunkName: "main" */ '@cpanel/pages/Home.vue')

const SettingPage = () => import(/* webpackChunkName: "settings" */ '@cpanel/pages/settings')

const UsersPage = () => import(/* webpackChunkName: "user" */ '@cpanel/pages/user/Users')
const UserPage = () => import(/* webpackChunkName: "user" */ '@cpanel/pages/user/User')

const PlacesPage = () => import(/* webpackChunkName: "place" */ '@cpanel/pages/place/Places')

const Widgets = () => import(/* webpackChunkName: "widget" */ '@cpanel/pages/widget/Widgets')
const Widget = () => import(/* webpackChunkName: "widget" */ '@cpanel/pages/widget/Widget')

const ConferenceHistory = () => import(/* webpackChunkName: "conferencehistory" */ '@cpanel/pages/conference-history/ConferenceHistory')

const Chats = () => import(/* webpackChunkName: "chathistory" */ '@cpanel/pages/chat/Chats')
const SingleChatLayout = () => import(/* webpackChunkName: "chathistory" */ '@cpanel/pages/chat/SingleChatLayout')

Vue.use(Router)

const DefaultLayout = ConsoleLayout

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { layout: AuthLayout }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage,
    meta: { layout: AuthLayout }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordPage,
    meta: { layout: AuthLayout }
  },
  {
    path: '/videochat',
    component: Videochat,
    meta: { title: 'Видеовызов' }
  },
  {
    name: 'conference-history',
    path: '/conference-history',
    component: ConferenceHistory,
    meta: { title: 'Журнал видеовызовов' }
  },
  {
    name: 'chats',
    path: '/chats',
    component: Chats,
    meta: { title: 'Активные чаты' },
    children: [
      {
        name: 'chat-view',
        path: 'view/:chatId',
        props: true,
        component: SingleChatLayout,
        meta: { transition: false }
      }
    ]
  },
  {
    path: '/place/list',
    component: PlacesPage,
    meta: { title: 'Локации' }
  },
  {
    path: '/user/list',
    component: UsersPage,
    meta: { title: 'Пользователи' }
  },
  {
    path: '/user/:userId',
    component: UserPage,
    props: true,
    meta: { title: 'Пользователь' }
  },
  {
    path: '/widget/list',
    component: Widgets,
    meta: { title: 'Виджеты и сайты' }
  },
  {
    path: '/widget/:widgetId',
    component: Widget,
    props: true,
    meta: { title: 'Настройки виджета' }
  },
  {
    path: '/settings',
    component: SettingPage,
    redirect: { name: 'settings.general' },
    meta: { title: 'Настройки' },
    children: [
      {
        path: 'billing',
        name: 'settings.billing',
        meta: {},
        component: () => import('@cpanel/pages/settings/Billing')
      }
    ]
  },
  {
    path: '*',
    component: NotFoundPage,
    meta: {
      layout: MinimalLayout
    }
  }
]

// Set default layout for all paths with no explicit layout specified
routes.forEach(el => {
  el.meta = el.meta || {}
  if (!el.meta.layout) el.meta.layout = DefaultLayout
  if (el.children) {
    const parentLayout = el.meta.layout
    const parentTitle = el.meta.title
    el.children.forEach(child => {
      child.meta = child.meta || {}
      if (!child.meta.title) child.meta.title = parentTitle
      if (!child.meta.layout) child.meta.layout = parentLayout
    })
  }
})

export default new Router({ routes })
