import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// Helpers
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify)

const options = {
  theme: {
    primary: colors.blue.darken2
  }
}

export default new Vuetify(options)
