import { VideoroomApiFactory } from '@common/vue-plugins/janus/vue-plugin'
import Vue from 'vue'
import CommutatorModule from './commutator'
import $store from '../index'
const $janus = VideoroomApiFactory.getInstance()

const state = {
  // Id видеокомнаты на серевре Janus
  roomId: null,
  // Пинкод для доступа к видеокомнате
  pin: null,
  //
  autoConnect: true,
  //
  playing: false,
  //
  remoteStream: null,
  //
  localStream: null
}

const getters = {
  roomId(state) {
    return state.roomId
  },
  pin(state) {
    return state.pin
  },
  stream(state) {
    return state.localStream
  },
  playing(state) {
    return state.playing
  }
}

const actions = {
  async requestPreviewRoom({ state, commit, rootGetters }) {
    if (!state.roomId) {
      // запрашиваем видеокомнату
      const regData = await rootGetters['commutator/instance'].requestPreviewRoom()
      commit('SET_ROOM_ID', regData.roomId)
      commit('SET_PIN', regData.pin)
    }

    // Подключаемся к предоставленной видеокомнате
    await $janus.joinRoom({ roomId: state.roomId, pin: state.pin })
    $janus.on($janus.EVENTS.DISCONNECTED, () => {
      commit('SET_ROOM_ID', null)
      commit('SET_PIN', null)
      commit('SET_PLAYING', false)
    })
  },

  async play({ state, commit, dispatch }) {
    if (state.autoConnect && (!state.roomId || !$janus.isRoomJoined())) {
      await dispatch('requestPreviewRoom')
    }
    if (!$janus.isPublished) {
      await $janus.publishMe({
        audio: false,
        video: {
          width: { max: 160 },
          height: { max: 120 },
          frameRate: 12
        }
      })
      commit('SET_PLAYING', true)
      commit('SET_LOCAL_STREAM', $janus._stream)
    }
  },

  async pause({ commit }) {
    if ($janus.isPublished) {
      await $janus.unpublishMe(true)
    }
    commit('SET_LOCAL_STREAM', null)
    commit('SET_PLAYING', false)
  },

  async stop({ dispatch }) {
    await dispatch('pause')
    await $janus.disconnect()
  }
}

const mutations = {
  SET_ROOM_ID(state, roomId) {
    state.roomId = roomId
  },

  SET_PIN(state, pin) {
    state.pin = pin
  },

  SET_PLAYING(state, val) {
    state.playing = val
  },

  SET_LOCAL_STREAM(state, stream) {
    if (!stream) $janus.stopLocalStreams()
    state.localStream = stream
  }
}

const events = {
  onCommutatorConnected() {
    // return $store.dispatch('preview/play')
  },

  async onCommutatorDisconnected() {
    return $store.dispatch('preview/stop')
  }
}

const vm = new Vue()
vm.$watch(() => CommutatorModule.state.sessionId, val => {
  val ? events.onCommutatorConnected() : events.onCommutatorDisconnected()
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

