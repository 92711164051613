import moment from 'moment'
moment.locale('ru')

function install(Vue, options = {}) {
  if (install.installed) return
  install.installed = true
  Vue.$moment = Vue.prototype.$moment = moment
}

const MomentPlugin = {
  install
}

export default MomentPlugin
