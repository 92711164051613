import ConfirmDialog from './components/ConfirmDialog'

export default function(Vue, options) {
  const ComponentClass = Vue.extend(ConfirmDialog)
  const confirm = new ComponentClass({
    propsData: {}
  })

  const app = document.createElement('div')
  app.setAttribute('data-app', true)
  document.body.appendChild(app)
  app.appendChild(confirm.$mount().$el)

  return confirm
}
