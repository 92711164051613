<template>
  <div data-app>
    <v-dialog
      v-model="dialog"
      :persistent="false"
      :max-width="maxWidth"
      content-class="vuedl-layout"
      @keydown.esc="dismiss"
    >
      <v-card>
        <v-card-title v-if="!!title" class="headline">{{ title }}</v-card-title>
        <v-card-text>
          {{ text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="btnCancel" @click="dismiss">Отмена</v-btn>
          <v-btn color="primary" @click="confirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    btnCancel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      title: '',
      text: '',
      maxWidth: 500,
      dialog: false,
      promise: null
    }
  },
  methods: {
    show(options = {}) {
      const self = this
      if (typeof options === 'string') {
        this.text = options
        this.title = ''
      } else {
        this.title = options.title || ''
        this.text = options.text || ''
      }

      this.dialog = true
      return new Promise((resolve, reject) => { self.promise = { resolve, reject } })
    },

    hide() {
      this.dialog = false
    },

    confirm() {
      this.hide()
      if (this.promise) {
        this.promise.resolve(true)
        this.promise = null
      }
    },

    dismiss() {
      this.hide()
      if (this.promise) {
        this.promise.resolve(false)
        this.promise = null
      }
    }
  }
}
</script>

<style scoped>

</style>
