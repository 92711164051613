import io from 'socket.io-client'

const getWebSocketUrl = (url, namespace = '/') => {
  if (url.toLocaleString().indexOf('http') === 0) return url
  const wsUri = new URL(url, document.baseURI)
  wsUri.protocol = wsUri.protocol === 'https:' ? 'wss:' : 'ws:'
  const host = wsUri.origin + namespace
  return [host, wsUri.pathname, wsUri.href]
}

class CommutatorConnection extends EventTarget {
  constructor() {
    super()

    this.EVENTS = {
      CHAT_MESSAGE: 'chatMessage',
      INCOMING_CALL: 'incomingCall',
      INCOMING_CALL_CANCEL: 'incomingCallCancel',
      START_CONFERENCE: 'startConference',
      STOP_CONFERENCE: 'stopConference',
      DISCONNECT: 'disconnect',
      ERROR: 'error'
    }

    this.defaults = {}
    this.socket = null
    this.sessionId = null
  }

  connect(sessionId, options = {}) {
    options = { ...this.defaults, ...options }

    if (this.socket && this.socket.connected && this.sessionId !== sessionId) this.socket.disconnect()

    this.sessionId = sessionId
    const [wshost, wspath] = getWebSocketUrl(process.env.VUE_APP_COMMUTATOR_WS, '/agent')
    this.socket = io(wshost, {
      path: wspath,
      transports: ['polling', 'websocket'],
      autoConnect: true,
      query: { sessionId }
    })

    const promise = new Promise((resolve, reject) => {
      if (this.socket.connected) return resolve()
      this.socket.on('connect', resolve)
      this.socket.on('error', reject)
      this.socket.on('connect_error', reject)
      this.socket.on('connect_timeout', reject)
    })

    this.bindEvents()
    return promise
  }

  connected() {
    return this.socket && this.socket.connected && !!this.sessionId
  }

  bindEvents() {
    this.socket.on('exception', err => {
      this.dispatchEvent(new CustomEvent(this.EVENTS.ERROR, { detail: err }))
      console.log(`[Commutator] ${err.status}, ${err.message}`)
    })

    this.socket.on('disconnect', () => {
      this.dispatchEvent(new CustomEvent(this.EVENTS.DISCONNECT, { detail: null }))
    })

    this.socket.on('session destroyed', () => {
      console.log('[VideoWidget] Your session has been destroyed on server')
      // You can reconnect here
      if (this.socket.connected) this.disconnect()
    })

    this.socket.on('call request', (data) => {
      this.dispatchEvent(new CustomEvent(this.EVENTS.INCOMING_CALL, { detail: data }))
    })
    this.socket.on('call request cancel', (data) => {
      this.dispatchEvent(new CustomEvent(this.EVENTS.INCOMING_CALL_CANCEL, { detail: data }))
    })
    this.socket.on('start conference', (data) => {
      this.dispatchEvent(new CustomEvent(this.EVENTS.START_CONFERENCE, { detail: data }))
    })
    this.socket.on('stop conference', (data) => {
      this.dispatchEvent(new CustomEvent(this.EVENTS.STOP_CONFERENCE, { detail: data }))
    })
    this.socket.on('chat message', (data) => {
      this.dispatchEvent(new CustomEvent(this.EVENTS.CHAT_MESSAGE, { detail: data }))
    })
  }

  disconnect() {
    this.sessionId = null
    this.socket.disconnect()
    this.socket = null
  }

  emit(eventName, args = null, timeout = 30) {
    if (!this.socket || !this.socket.connected) {
      return Promise.reject(`[Commutator] No commutator connection. Cannot emit command [${eventName}]`)
    }

    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(`[Commutator] command timeout [${eventName}]`)
      }, timeout * 1000)

      this.socket.emit(eventName, args, data => {
        clearTimeout(timer)
        resolve(data)
      })
    })
  }

  /**
   * Запросить видеокомнату для персональной конференции с клиентом
   */
  requestRoom() {
    return this.emit('requestRoom')
  }

  /**
   * Запросить видеокомнату для приглашающей трансляции на сайте\в приложении
   * @return {*}
   */
  requestPreviewRoom() {
    return this.emit('requestPreviewRoom')
  }

  /**
   * Принять входящий вызов. Отправляется на бэкенд в ответ на запрос клиента вызвать агента для общения
   */
  acceptCall(requestId, data) {
    return this.socket.emit('call request', { requestId, command: 'accept', ...data })
  }

  // Отклонить входящий вызов
  declineCall(requestId, data = {}) {
    this.socket.emit('call request', { requestId, command: 'decline', ...data })
  }

  /**
   * Отправить на бэкенд подтверждение, что агент подключился к видеокомнате и полностью готов к конференции с клиентом.
   * После получения этого события бэкенд отправляет клиенту команду на вход в видеокомнату, где его уже
   * ожидает агент
   * @param requestId
   * @param data
   */
  startConferenceConfirm(requestId, data = {}) {
    this.socket.emit('start conference', { requestId, command: 'ok', ...data })
  }

  /**
   * Уведомить бэкенд уведомление о том, что агент инициировал завершение вызова (конференции)
   * @param data
   */
  hangup(data = {}) {
    if (this.socket) this.socket.emit('hangup', data)
  }

  // **************************************************************************************************************** //

  /**
   * Отправляет сообщение в чат
   * @param chatId
   * @param text
   */
  chatSendMessage(chatId, message) {
    return this.emit('chat message', { chatId, message }, 5)
  }

  async chatQueryMessages(chatId, query) {
    return this.emit('chat query messages', { chatId, query }, 7)
  }
}

const commutator = new CommutatorConnection()
export { commutator }
