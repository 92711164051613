export class VideoroomEvent extends CustomEvent {
  constructor(event, payload) {
    super(event, { detail: payload })
  }
}

export const VIDEOROOM_EVENTS = {
  DISCONNECTED: 'disconnected',
  ERROR: 'error',
  ROOM_PUBLISHER_IN: 'room.publisher.in', // Участник начал вести видеортансляцию
  ROOM_PUBLISHER_OUT: 'room.publisher.out', // Участник прекратил видеотрансляцию
  ROOM_DESTROYED: 'room:destroyed', // Видеокомната уничтожена
  ROOM_MESSAGE: 'room.message' // Получено прочее системное сообщение
}

export class VideoroomEmitter extends EventTarget {
  constructor() {
    super()
    this.EVENTS = VIDEOROOM_EVENTS
  }

  emit(event, payload = null) {
    this.dispatchEvent(new VideoroomEvent(event, payload))
  }

  on(event, callback) {
    this.addEventListener(event, callback)
  }

  off(event, callback) {
    this.removeEventListener(event, callback)
  }
}
