<template>
  <div data-app>
    <v-dialog
      v-model="dialog"
      :persistent="false"
      :max-width="maxWidth"
      content-class="vuedl-layout"
      @keydown.esc="dismiss"
    >
      <v-card>
        <v-card-title v-if="!!title" class="headline">{{ title }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editedValue"
            autofocus
            required
            :label="text"
            @keypress.enter="confirm"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="dismiss">Cancel</v-btn>
          <v-btn flat color="primary" @click="confirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      text: 'Enter value',
      editedValue: '',
      maxWidth: 500,
      dialog: false,
      promise: null
    }
  },
  methods: {
    show(options = {}) {
      const self = this
      this.title = options.title || ''
      this.text = options.text || 'Enter value'
      this.editedValue = options.value || ''
      this.dialog = true
      return new Promise((resolve, reject) => { self.promise = { resolve, reject } })
    },

    hide() {
      this.dialog = false
    },

    confirm() {
      this.hide()
      if (this.promise) {
        this.promise.resolve(this.editedValue)
        this.promise = null
      }
    },

    dismiss() {
      this.hide()
      if (this.promise) {
        this.promise.resolve(false)
        this.promise = null
      }
    }
  }
}
</script>

<style scoped>

</style>
