const state = {
  commonMenu: {
    common: {
      title: 'Активность',
      items: [
        // {
        //   icon: 'mdi-badge-account-horizontal-outline',
        //   title: 'Посетители на сайте',
        //   link: '/traffic'
        // },
        {
          id: 'chats',
          icon: 'mdi-message-outline',
          title: 'Чаты',
          link: '/chats',
          badge: null
        },
        {
          icon: 'mdi-video-outline',
          title: 'Видеовызовы',
          link: '/conference-history'
        }
      ]
    },
    settings: {
      title: 'Настройки',
      items: [
        {
          icon: 'mdi-account-outline',
          title: 'Пользователи',
          link: '/user/list'
        },
        {
          icon: 'mdi-map-marker-radius-outline',
          title: 'Локации',
          link: '/place/list'
        },
        {
          icon: 'mdi-widgets-outline',
          title: 'Настройка виджета',
          link: '/widget/list'
        }
      ]
    }
  },
  userMenu: null
}

// getters
const getters = {
  name() {
    return process.env.VUE_APP_APP_NAME
  },

  menu(state) {
    const resultMenu = Object.assign({}, state.commonMenu)
    for (const sector of Object.keys(state.userMenu || {})) {
      if (!resultMenu.hasOwnProperty(sector)) {
        resultMenu[sector] = {
          title: state.userMenu[sector].title
        }
      }
      resultMenu[sector].items = resultMenu[sector].items || []
      for (const item of state.userMenu[sector].items) {
        resultMenu[sector].items.push({
          name: item.name || '',
          title: item.title || '',
          icon: item.icon || '',
          link: item.link || null
        })
      }
    }
    return resultMenu
  }
}

// actions
const actions = {
  setChatMenuBadge({ state, commit }, content) {
    const itemIndex = state.commonMenu.common.items.findIndex(item => (item.id === 'chats'))
    if (itemIndex < 0) {
      console.error('CHAT item menu not found')
      return
    }
    commit('SET_COMMON_MENU_ITEM_BADGE', { itemIndex, content })
  }
}

// mutations
const mutations = {
  SET_USER_MENU(state, payload) {
    state.userMenu = payload
  },
  SET_COMMON_MENU_ITEM_BADGE(state, payload) {
    const { itemIndex, content } = payload
    this._vm.$set(state.commonMenu.common.items[itemIndex], 'badge', content)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
