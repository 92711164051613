import { onLogin as apolloOnLogin, onLogout as apolloOnLogout } from '@cpanel/includes/apollo/vue-apollo'
import { apolloProvider } from '@cpanel/includes/apollo/apollo-default-provider'

const state = {
  user: null,
  lastLogonUser: null,
  token: null,
  integrationId: null,
  // Точка продаж, из которой ведет вещание консультант (объект)
  place: null
}

// getters
const getters = {

  user(state) {
    return state.user
  },

  integrationId(state) {
    return state.integrationId
  },

  lastLogonUser(state) {
    return state.lastLogonUser
  },

  place(state) {
    return state.place
  },

  placeId(state) {
    return (state.place || {})._id || null
  },

  isLoggedIn(state) {
    return !!state.user
  }

}

// actions
const actions = {
  async login({ commit, dispatch }, authData) {
    const user = {
      _id: authData.user._id,
      _tenant: authData.user._tenant,
      name: authData.user.name,
      email: authData.user.email,
      role: authData.user.role,
      createdAt: authData.user.createdAt
    }
    await apolloOnLogin(apolloProvider.defaultClient, authData.token)
    commit('SET_USER', user)
    commit('SET_INTEGRATION_ID', authData.integrationId)
    commit('SET_LAST_LOGON_USER', user)
    dispatch('save')
  },

  async logout({ state, commit, dispatch }) {
    await apolloOnLogout(apolloProvider.defaultClient)
    commit('SET_LAST_LOGON_USER', state.user)
    commit('SET_USER', null)
    commit('SET_INTEGRATION_ID', null)
    commit('SET_TOKEN', null)
    dispatch('save')
  },

  setToken({ commit, dispatch }, token) {
    commit('SET_TOKEN', token)
    dispatch('save')
  },

  setPlace({ commit, dispatch }, place) {
    commit('SET_PLACE', place)
    dispatch('save')
  },

  save({ state }) {
    localStorage.setItem('app$user', JSON.stringify(state, null, 2))
  },

  restore({ state, commit }) {
    const dataJson = localStorage.getItem('app$user')
    if (dataJson) {
      try {
        const data = JSON.parse(dataJson)
        commit('SET_USER', data.user || null)
        commit('SET_INTEGRATION_ID', data.integrationId || null)
        commit('SET_LAST_LOGON_USER', data.lastLogonUser || null)
        commit('SET_TOKEN', data.token || null)
      } catch (e) {
        localStorage.removeItem('app$user')
      }
    }
  }

}

// mutations
const mutations = {
  SET_USER(state, user) {
    state.user = user
  },

  SET_LAST_LOGON_USER(state, user) {
    state.lastLogonUser = user
  },

  SET_INTEGRATION_ID(state, integrationId) {
    state.integrationId = integrationId
  },

  SET_TOKEN(state, payload) {
    state.token = payload
  },

  SET_PLACE(state, place) {
    state.place = place
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
