if (window.RTCPeerConnection) {
  if (!window.RTCPeerConnection.prototype.getLocalStreams && window.RTCPeerConnection.prototype.getSenders) {
    window.RTCPeerConnection.prototype.getLocalStreams = function() {
      const stream = new MediaStream()
      this.getSenders().forEach(function(sender) {
        if (sender.track) stream.addTrack(sender.track)
      })
      return [stream]
    }
  }
}
