import Vue from 'vue'

export const DATE_FORMAT = {
  CALENDAR: 'calendar',
  FROM_NOW: 'from now'
}

export default function(dt, format) {
  const date = new Date(dt)
  if (format === DATE_FORMAT.CALENDAR) {
    return Vue.$moment(date).calendar()
  } else if (format === DATE_FORMAT.FROM_NOW) {
    return Vue.$moment(date).fromNow()
  } else {
    return Vue.$moment(date).format('DD.MM.YYYY HH:mm:ss')
  }
}
