import PromptDialog from './components/PromptDialog'

export default function(Vue, options) {
  const ComponentClass = Vue.extend(PromptDialog)
  const prompt = new ComponentClass({
    propsData: {}
  })

  const app = document.createElement('div')
  app.setAttribute('data-app', true)
  document.body.appendChild(app)
  app.appendChild(prompt.$mount().$el)

  return prompt
}
