import { onError } from 'apollo-link-error'
import store from '@/store'

export default onError(({ graphQLErrors, networkError, forward, operation }) => {
  if (graphQLErrors && graphQLErrors[0].message === 'Unauthorized') {
    store.dispatch('user/logout')
    return
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})
