<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import DefaultLayout from './layouts/MinimalLayout'

export default {
  components: { DefaultLayout },
  data() {
    return { layout: DefaultLayout }
  },
  watch: {
    $route(to) { this.updateLayout(to) }
  },
  mounted() {
    this.updateLayout(this.$router.currentRoute)
  },
  methods: {
    updateLayout(route) {
      if (!route.meta.layout && route.fullPath !== '/') console.warn('No layout defined for route', route)
      this.layout = route.meta.layout || DefaultLayout
    }
  }
}
</script>
