import { VideoroomEmitter } from './videoroom-events'
import { VideoroomSubscriber } from './videoroom-subscriber'

export class VideoroomApi extends VideoroomEmitter {
  constructor(janusClient, options = {}) {
    super()
    this.janus = janusClient
    this.connection = null
    this._disconnectingPromise = null
    this.session = null
    this.roomId = null
    this.roomPin = null
    this.managementHandle = null
    this.participantId = null
    this.subscriptions = []
    this._htmlVideoEl = null
    this._stream = null
    this._isPublished = false
    this.connectionId = 'mg-' + Math.random().toString(36).substring(2)
  }

  /**
   * Подключение к видеокомнате
   * @param options
   * @return {Promise<null>}
   */
  async joinRoom(options = {}) {
    const defaults = {
      roomId: null,
      pin: null
    }
    const opts = { ...defaults, ...options }

    // Создаем соединение с Янус и новую сессию
    await this._disconnectingPromise
    await this._sessionStart()
    // Создаем handle для управления: прослушивание событий комнаты, публикации и пр
    this.managementHandle = await this.session.attachPlugin('janus.plugin.videoroom')

    // Подключаемся к видеокомнате
    const data = await this.managementHandle.join({
      ptype: 'publisher',
      room: parseInt(opts.roomId),
      pin: opts.pin
    })
    this.roomId = data.room.toString()
    this.roomPin = opts.pin || null
    this.participantId = data.id
    return data
  }

  async _disconnect() {
    await this.unsubscribeAll()
    if (this.managementHandle) await this.managementHandle.leave().catch(() => {})

    this.roomId = null
    this.roomPin = null
    this.participantId = null
    this._htmlVideoEl = null
    this._stream = null
    this._isPublished = false

    try {
      if (this.managementHandle) await this.managementHandle.detach()
    } catch (e) {
      // nop
    }
    this.managementHandle = null

    try {
      if (this.session) await this.session.destroy()
    } catch (e) {
      // nop
    }
    this.session = null

    try {
      if (this.connection) await this.connection.close()
    } catch (e) {
      // nop
    }
    this.connection = null
  }

  async disconnect() {
    this._disconnectingPromise = this._disconnect().then(() => { this._disconnectingPromise = null })
    return this._disconnectingPromise
  }

  /**
   * Транслирует в видеокомнату видео и аудио пользователя с локальной камеры и микрофона
   * @param options
   * @return {Promise<void>}
   */
  async publishMe(options = {}) {
    this._validateConnection()

    const defaults = {
      htmlVideo: null,
      audio: true,
      video: true
    }
    const opts = { ...defaults, ...options }
    if (opts.htmlVideo) this._htmlVideoEl = opts.htmlVideo
    this._stream = await this.managementHandle.getUserMedia({ audio: opts.audio, video: opts.video })
    if (this._htmlVideoEl) this._htmlVideoEl.srcObject = this._stream
    const { _plainMessage: { plugindata: { data }}} = await this.managementHandle.publish(this._stream, {})
    this._isPublished = true

    return data
  }

  stopLocalStreams() {
    // this.managementHandle._stopLocalMedia()
    // if (this._stream) this._stream.getTracks().forEach(track => { track.stop() })
  }
  /**
   * Прекращает трансляцию видеоданных пользователя
   *
   * @return {Promise<*>}
   */
  async unpublishMe(silent = false) {
    // this.stopLocalStreams()
    this._validateConnection()
    try {
      await this.managementHandle.unpublish()
    } catch (e) {
      if (!silent) throw e
    }

    this._isPublished = false
    this._stream = null
    if (this._htmlVideoEl) this._htmlVideoEl.srcObject = null
  }

  get isPublished() {
    return this._isPublished
  }

  async getPublishers() {
    this._validateConnection()
    const participants = await this.managementHandle.listParticipants(this.roomId)
    const publishers = participants.filter(p => p.publisher)
    return publishers.map(p => ({ id: p.id, video_codec: p.video_codec }))
  }

  async waitAnyPublisher(timeout = 10) {
    this._validateConnection()
    const publishers = await this.getPublishers()
    if (publishers.length) return publishers

    return new Promise((resolve, reject) => {
      const onPublisherIn = ({ detail }) => {
        clearTimeout(timer)
        this.off(this.EVENTS.ROOM_PUBLISHER_IN, onPublisherIn)
        resolve(detail)
      }
      const onTimeout = () => {
        this.off(this.EVENTS.ROOM_PUBLISHER_IN, onPublisherIn)
        reject(new Error('Timeout'))
      }

      const timer = setTimeout(onTimeout.bind(this), timeout * 1000)
      this.on(this.EVENTS.ROOM_PUBLISHER_IN, onPublisherIn.bind(this))
    })
  }

  bindVideo(htmlVideo) {
    if (this._htmlVideoEl && !htmlVideo) this._htmlVideoEl.srcObject = null
    this._htmlVideoEl = htmlVideo
    if (this._htmlVideoEl) this._htmlVideoEl.srcObject = this._stream || null
  }

  /**
   * Получает видеопоток от указанного участника видеокомнаты
   *
   * @param publisherId
   * @param options
   * @return {Promise<VideoroomSubscriber>}
   */
  async subscribeTo(publisherId, options = {}) {
    const subscriber = await this._createSubscription(publisherId, options)
    if (options.autoplay !== false) {
      await subscriber.getPublisherStream(this.roomId, this.roomPin, {
        audio: options.audio !== false,
        offer_audio: options.audio !== false,
        video: options.video !== false,
        offer_video: options.video !== false
      })
    }
    this.subscriptions.push(subscriber)
    return subscriber
  }

  _unsubscribe(id) {
    const idx = this._findSubscriptionIdx(id)
    if (idx < 0) return Promise.resolve(false)
    const subscription = this.subscriptions[idx]
    this._removeSubscription(subscription)
    return subscription.destroy()
  }

  unsubscribeFrom(publisherId) {
    const subscription = this.findSubscription(publisherId)
    return subscription ? this._unsubscribe(subscription.id) : Promise.resolve(false)
  }

  unsubscribeAll() {
    const promises = []
    for (const subscription of this.subscriptions) {
      promises.push(this._unsubscribe(subscription.id))
    }
    return Promise.all(promises)
  }

  findSubscription(publisherId) {
    return this.subscriptions.find(s => (s.publisherId === publisherId))
  }

  isRoomJoined() {
    try {
      this._validateConnection()
    } catch (e) {
      return false
    }
    return true
  }

  _validateConnection(level = 4) {
    if (!this.connection) throw Error('There is no connection established to Janus')
    if ((level > 1) && !this.session) throw Error('There is no session on Janus')
    if ((level > 2) && !this.managementHandle) throw Error('Videoroom plugin does not attached to session')
    if ((level > 3) && !this.roomId) throw Error('You does not join the room')
    return true
  }

  async _createSubscription(publisherId, options = {}) {
    const subscriber = new VideoroomSubscriber(this, publisherId, options)
    await subscriber.init()
    return subscriber
  }

  _findSubscriptionIdx(id) {
    return this.subscriptions.findIndex(el => (el.id === id))
  }

  _removeSubscription(subscription) {
    const idx = this._findSubscriptionIdx(subscription.id)
    if (idx >= 0) this.subscriptions.splice(idx)
  }

  _removeSubscriptionById(id) {
    const idx = this._findSubscriptionIdx(id)
    if (idx >= 0) this._removeSubscription(this.subscriptions[idx])
  }

  async _sessionStart() {
    // Устанавливаем соедиенение с Janus
    if (!this.connection) {
      this.session = null
      this.connection = await this.janus.createConnection(this.connectionId)
      this.connection.on('error', this.onConnectionError.bind(this))
      this.connection.on('close', this.onConnectionClose.bind(this))
      this.connection.on('message', this.onMessage.bind(this))
    }
    // Создаем сессию на Janus
    this.session = await this.connection.createSession()
  }

  _onRoomDestroyed(data) {
    this.roomId = null
    this.roomPin = null
    this.participantId = null
    this.managementHandle = null
    this._isPublished = false
    this.subscriptions = []
    this.emit(this.EVENTS.ROOM_DESTROYED, data)
  }

  _reset() {
    this.connection = null
    this.session = null
    this.roomId = null
    this.roomPin = null
    this.managementHandle = null
    this.participantId = null
    this._isPublished = false
    this.subscriptions = []
  }

  async onMessage({ _plainMessage: message }) {
    if (message.janus === 'event') {
      const { plugindata: { data }} = message
      if (data.room !== parseInt(this.roomId)) return
      if (data.videoroom === 'destroyed') {
        this._onRoomDestroyed(data)
      } else if (data.publishers) {
        this.emit(this.EVENTS.ROOM_PUBLISHER_IN, data.publishers)
      } else if (data.unpublished) {
        this.emit(this.EVENTS.ROOM_PUBLISHER_OUT, data.unpublished)
      } else {
        this.emit(this.EVENTS.ROOM_MESSAGE, data)
      }
    }
  }

  onConnectionClose() {
    this._reset()
    this.emit(this.EVENTS.DISCONNECTED)
  }

  onConnectionError(err) {
    this.emit(this.EVENTS.ERROR, err)
  }
}
