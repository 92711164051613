const state = {
  show: false,
  title: null,
  message: null,
  buttons: [],
  result: null
}

// getters
const getters = {
  show(state) {
    return state.show
  },
  title(state) {
    return state.title
  },
  message(state) {
    return state.message
  },
  buttons(state) {
    return state.buttons
  },
  result(state) {
    return state.result
  }
}

// actions
const actions = {
  alert({ commit }, payload) {
    commit('SET_TITLE', typeof payload === 'object' ? payload.title : '')
    commit('SET_MESSAGE', typeof payload === 'object' ? payload.message : payload)
    commit('SET_BUTTONS', [{ text: 'OK', result: null, $attrs: { text: true }}])
    commit('SET_SHOW', true)
  },
  error({ commit }, payload) {
    commit('SET_TITLE', 'Ошибка')
    if (payload instanceof Error) {
      commit('SET_MESSAGE', payload.message || 'Произошла неизвестная ошибка')
    } else {
      commit('SET_MESSAGE', payload.toString())
    }
    commit('SET_BUTTONS', [{ text: 'Закрыть', result: null, $attrs: { text: true }}])
    commit('SET_SHOW', true)
  },
  hide({ commit }) {
    commit('SET_TITLE', null)
    commit('SET_MESSAGE', null)
    commit('SET_BUTTONS', [])
    commit('SET_SHOW', false)
  },
  setResult({ commit }, res) {
    commit('SET_RESULT', res)
  }
}

// mutations
const mutations = {
  SET_TITLE(state, payload) {
    state.title = payload
  },
  SET_MESSAGE(state, payload) {
    state.message = payload
  },
  SET_BUTTONS(state, payload) {
    state.buttons = payload
  },
  SET_SHOW(state, payload) {
    state.show = !!payload
    if (state.show) state.result = null
  },
  SET_RESULT(state, payload) {
    state.result = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
