import { commutator } from '@cpanel/includes/commutator'
import ApolloChatListQuery from '@cpanel/graphql/chat-list.gql'
import $store from '../index'
import { apolloProvider } from '@cpanel/includes/apollo/apollo-default-provider'

const $apollo = apolloProvider.defaultClient

const state = {
  chats: []
}

const getters = {
  chats(state) {
    return state.chats
  },

  getChatIndex(state) {
    if (!Array.isArray(state.chats)) {
      return -1
    }
    return chatId => {
      return state.chats.findIndex(chat => (chat.chatId && (chat.chatId === chatId)))
    }
  },

  getChat(state, getters) {
    if (!Array.isArray(state.chats)) {
      return null
    }
    return chatId => {
      const idx = getters.getChatIndex(chatId)
      return idx < 0 ? null : state.chats[idx]
    }
  },

  unreadMessageCount(state) {
    return state.chats.reduce((sum, chat) => (sum + (chat.$unreadCount || 0)), 0)
  }
}

const actions = {
  async sendMessage({ state }, messageData) {
    const { chatId, message } = messageData || {}
    await this.dispatch('commutator/chatSendMessage', { chatId, message })
  },

  async queryChatList({ commit }) {
    const { data: { chatList }} = await $apollo.query({
      query: ApolloChatListQuery,
      variables: { filter: { active: true, limit: 20 }},
      fetchPolicy: 'no-cache'
    })
    commit('SET_CHATS', chatList)
  },

  async queryChat({ state, getters, commit }, chatId) {
    const { data: { chatList }} = await $apollo.query({
      query: ApolloChatListQuery,
      variables: { filter: { _id: chatId }}
    })

    if (!chatList || chatList.length !== 1) {
      console.error('[VUEX:chat/queryChat] Query chat by id returns suspected result', chatList)
      throw new Error('Query chat by id returns suspected result')
    }
    const chatData = chatList[0]

    let chatIndex = getters.getChatIndex(chatId)
    if (chatIndex < 0) {
      commit('ADD_CHAT_FIRST', chatData)
      chatIndex = 0
    } else {
      commit('UPDATE_CHAT', { chatIndex, chatData })
    }

    return chatIndex
  },

  async queryMessages({ commit, getters }, payload) {
    const { chatId, query } = payload || {}
    if (!chatId) {
      console.error('[queryMessages] error. ChatId parameter is empty', payload)
      return
    }
    const chatIndex = getters.getChatIndex(chatId)
    if (chatIndex < 0) {
      console.error('ChatId not found', payload)
      return
    }

    const messages = await this.dispatch('commutator/chatQueryMessages', { chatId, query })
    commit('SET_CHAT_MESSAGES', { chatIndex, messages })
  },

  async appendMessage({ state, getters, commit, dispatch }, messageData) {
    const { chatId, message } = messageData
    if (!chatId) {
      throw new Error('ChatId is empty in message')
    }
    let chatIndex = getters.getChatIndex(chatId)
    if (chatIndex < 0) {
      chatIndex = await dispatch('queryChat', chatId)
      if (chatIndex >= 0) {
        await dispatch('queryMessages', { chatId, query: { limit: 50 }})
      }
    } else {
      commit('ADD_CHAT_MESSAGE', { chatIndex, message })
    }
    commit('INCREASE_CHAT_UNREAD', chatIndex)
    if (chatIndex > 0) commit('MOVE_CHAT_UP', chatIndex)
  },

  resetUnreadCount({ state, commit, getters }, chatId) {
    const chatIndex = getters.getChatIndex(chatId)
    if (chatIndex >= 0) {
      commit('RESET_CHAT_UNREAD', chatIndex)
    }
  }
}

const mutations = {
  ADD_CHAT_FIRST(state, chatData) {
    state.chats.unshift(chatData)
  },
  UPDATE_CHAT(state, { chatIndex, chatData }) {
    this._vm.$set(state.chats, chatIndex, chatData)
  },
  MOVE_CHAT_UP(state, chatIndex) {
    if (!chatIndex) return
    const chat = state.chats[chatIndex]
    state.chats.splice(chatIndex, 1)
    state.chats.unshift(chat)
  },
  ADD_CHAT_MESSAGE(state, { chatIndex, message }) {
    if (!state.chats[chatIndex].messages) {
      this._vm.$set(state.chats[chatIndex], 'messages', [])
    }
    state.chats[chatIndex].messages.push(message)
    this._vm.$set(state.chats[chatIndex], 'updatedAt', new Date())
  },
  SET_CHAT_MESSAGES(state, { chatIndex, messages }) {
    this._vm.$set(state.chats[chatIndex], 'messages', messages)
  },
  SET_CHATS(state, chatList) {
    state.chats = chatList
  },
  INCREASE_CHAT_UNREAD(state, chatIndex) {
    if (!state.chats[chatIndex]) {
      console.error(`[VUEX:CHAT:INCREASE_CHAT_UNREAD] Wrong chat index: ${chatIndex}`)
      return
    }
    const count = state.chats[chatIndex].$unreadCount || 0
    this._vm.$set(state.chats[chatIndex], '$unreadCount', count + 1)
  },
  RESET_CHAT_UNREAD(state, chatIndex) {
    this._vm.$set(state.chats[chatIndex], '$unreadCount', 0)
  }
}

const events = {
  onChatMessage({ detail: message }) {
    return $store.dispatch('chat/appendMessage', message)
  }
}

commutator.addEventListener(commutator.EVENTS.CHAT_MESSAGE, events.onChatMessage.bind($store))

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
