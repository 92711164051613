export class VideoroomSubscriber {
  constructor(videoroomApi, publisherId, options = {}) {
    const defaults = {
      htmlVideo: null,
      timeout: 7000
    }

    this.api = videoroomApi
    this.options = { ...defaults, ...options }

    this.id = Math.random().toString(36).substring(2)
    this._handle = null
    this._timeoutHangle = null
    this._htmlVideoEl = this.options.htmlVideo
    this._streams = null
    this.publisherId = publisherId
  }

  /**
   * @return {Promise<*>}
   */
  async init() {
    // Создаем отдельный handle для приема данных
    this._handle = await this.api.session.attachPlugin('janus.plugin.videoroom')
    this._handle.on('message', this.onMessage.bind(this))
    this._timeoutHangle = null
    return this._handle._id
  }

  async destroy() {
    await this._handle.leave().catch(() => {})
    await this._handle.detach()
  }

  /**
   * Устанавливает новое соединение с видеокомнатой для получения видеоданных конкретного издателя
   *
   * @param roomId
   * @param pin
   * @param options
   * @return {Promise}
   */
  async getPublisherStream(roomId, pin, options = {}) {
    return new Promise((resolve, reject) => {
      this._timeoutHangle = setTimeout(reject.bind(this, 'Waiting publisher stream timeout'), this.options.timeout)
      this._handle.once('pc:track:remote', ({ streams }) => {
        if (this._timeoutHangle) clearTimeout(this._timeoutHangle)
        this._timeoutHangle = null
        this._streams = streams
        if (this._htmlVideoEl) this._htmlVideoEl.srcObject = this._streams[0]
        resolve(streams[0])
      })

      // Подключаемся к видеокомнате
      const opts = {
        audio: true, // depending on whether or not audio should be relayed; true by default>,
        video: true, // depending on whether or not video should be relayed; true by default>,
        data: true, // depending on whether or not data should be relayed; true by default>,
        offer_audio: true, // whether or not audio should be negotiated; true by default if the publisher has audio>,
        offer_video: true, // whether or not video should be negotiated; true by default if the publisher has video>,
        offer_data: true, // whether or not datachannels should be negotiated; true by default if the publisher has datachannels>,
        close_pc: true, // depending on whether or not the PeerConnection should be automatically closed when the publisher leaves; true by default>,
        // private_id: // <unique ID of the publisher that originated this request; optional, unless mandated by the room configuration>,
        // substream: 0, // <substream to receive (0-2), in case simulcasting is enabled; optional>,
        // temporal: 0, // <temporal layers to receive (0-2), in case simulcasting is enabled; optional>,
        // fallback: 250000, // <How much time (in us, default 250000) without receiving packets will make us drop to the substream below>,
        // spatial_layer: 0, // <spatial layer to receive (0-2), in case VP9-SVC is enabled; optional>,
        // temporal_layer: 0 <temporal layers to receive (0-2), in case VP9-SVC is enabled; optional>
        ...options
      }
      this._handle.joinAsSubscriber({
        room: parseInt(roomId),
        pin: pin,
        feed: this.publisherId,
        ...opts
      }).catch(reject)
    })
  }

  /**
   * Позволяет задать html элемент "video", в котором будут транслироваться видеоданные от издателя,
   * на которого мы подписаны
   * @param htmlVideoEl
   */
  bindVideo(htmlVideoEl) {
    this._htmlVideoEl = htmlVideoEl
    if (this._htmlVideoEl) {
      this._htmlVideoEl.srcObject = this._streams ? this._streams[0] : null
    }
  }

  async _onHangup() {
    this._streams = null
    if (this._htmlVideoEl) this._htmlVideoEl.srcObject = null
    await this._handle.detach()
    this._handle.closePeerConnection()
    this.api._removeSubscription(this)
  }

  onMessage({ _plainMessage: message }) {
    const { janus: event } = message
    if (event === 'hangup') {
      Promise.resolve().then(this._onHangup.bind(this))
    } else if (event === 'event') {
      // const { plugindata: { data }} = message
    } else {
      // console.log(message)
    }
  }
}
